.footer {
    width: 100%;
    bottom: 0;
    left: 0;
    color: rgba(37, 7, 7, 0.842);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #30475E;
}

.footer .content {
    padding: 10px 20px;
    flex: 1 1 200px; /* Responsive flex-basis */
}

.footer .content h2 {
    font-size: 22px;
    position: relative;
}

.footer .content h2::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    height: 2px;
    width: 70px;
    background: rgb(3, 87, 241);
}

.footer .about {
    flex-basis: 35%;
}

.footer .about .social {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
}

.footer .about .social a {
    margin-right: 20px;
    width: 40px;
    height: 40px;
    background: rgb(80, 48, 66);
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    transition: 0.2s;
}

.footer .about .social a:hover {
    background: rgb(255, 3, 3);
}

.footer .links {
    flex-basis: 20%;
}

.footer .links ul {
    list-style: none;
}

.footer .links ul li {
    margin-top: 10px;
}

.footer .links ul li a {
    text-decoration: none;
    color: rgb(206, 201, 201);
}

.footer .links ul li a:hover {
    color: rgb(241, 38, 2);
}

.footer .newsletter {
    flex-basis: 25%;
}

.footer .newsletter p {
    margin: 15px 0;
    font-size: 16px;
}

.footer .newsletter input {
    width: 100%;
    height: 35px;
    border: 1px solid gray;
    outline: none;
    padding-left: 20px;
    font-size: 16px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.footer .newsletter button {
    width: 150px;
    padding: 5px;
    font-size: 18px;
    border: none;
    background: cornflowerblue;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s;
}

.footer .newsletter button:hover {
    background: rgb(3, 87, 241);
}

.message {
    color: green; /* Color for success messages */
    margin-top: 10px;
}

@media (max-width: 950px) {
    .footer .content {
        flex-basis: 50%;
        margin-bottom: 20px;
    }
}

@media (max-width: 590px) {
    .footer .content {
        flex-basis: 100%;
        margin-bottom: 20px;
    }

    .footer .newsletter button {
        width: 100%;
    }
}
