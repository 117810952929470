.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: #fe0222;
    transform-origin: 0%;
    z-index: 9999;
  }
  