@media only screen and (min-width:320px){
    /* #navTitle{
font-size:1rem;
font-family:'pacifico','serif';
    } */

    #memName{
font-size:.5rem ;
    }
}
#memName{
    font-size:1rem ;
        }


/* @media only screen and (min-width:900px){
    #navTitle{
        font-size:1.33rem;
    }
} */

/* General Navbar styling */
.nav-title {
    color: red;
    margin-left: 10px;
    font-family:'pacifico','serif';
    font-weight: 400;
    font-size: 1.5rem;
  }
  
  /* Mobile specific changes */
  @media (max-width: 768px) {
    .nav-title {
      font-size: 1.2rem; /* Reduce font size for tablets */
    }
  }
  
  @media (max-width: 576px) {
    .nav-title {
      font-size: 1rem; /* Reduce font size for mobile */
    }
  
    /* Change full title to 'AFRS' for small screens */
    .navbar-brand h1 {
      display: none; /* Hide full title on small screens */
    }
  
    .navbar-brand::after {
      content: "AFRS"; /* Display AFRS instead of full name */
      display: block;
      color: red;
      font-family: 'Sofia', cursive;
      font-size: 1.2rem;
      margin-left: 10px;
    }
  }
  
  /* Styling for buttons */
  .museum-btn,
  .join-btn {
    font-family: 'Sofia', cursive;
    background: #e8f5e9;
    border: none;
    color: blue;
    cursor: pointer;
  }
  
  .museum-btn {
    text-decoration: underline;
  }
  