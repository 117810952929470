

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(13, 27, 42); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it covers all content */
  }
  
  .loading-overlay img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 42%;
  }